import React from 'react'
import { Link } from 'gatsby'
import { useSiteMetadata } from '../hooks/site-metadata'
import monoLogo from '../images/mono-logo.svg'

const links = [
    {
        category: 'Company',
        links: [
            { title: 'Home', url: '/' },
            { title: 'About', url: '/about' },
            { title: 'Blog', url: '/blog' },
        ],
    },
    {
        category: 'Product',
        links: [
            { title: 'Pricing', url: '/#pricing' },
            { title: 'Features', url: '/#features-podcast' },
        ],
    },

    {
        category: 'Help',
        links: [
            { title: 'Documentation', url: '/docs' },
            { title: 'Support', url: '/support' },
        ],
    },
    {
        category: 'Legal',
        links: [
            { title: 'Privacy', url: '/privacy' },
            { title: 'Terms of Service', url: '/terms' },
        ],
    },
]

export default props => {
    const meta = useSiteMetadata()
    return (
        <footer className='text-sm mt-20 px-8 py-8 bg-white border-t border-gray-200 w-full '>
            <div className='max-w-5xl mx-auto'>
                <div className='grid grid-cols-2 sm:flex flex-wrap justify-between'>
                    {links.map(l => {
                        return (
                            <div key={l.category} className='mt-3 sm:mt-0 w-full sm:w-auto mx-2'>
                                <h2 className='font-bold sm:font-medium text-gray-600 border-b border-gray-200 inline-block pb-2 uppercase tracking-wide text-xs'>
                                    {l.category}
                                </h2>
                                <ul className='mt-3'>
                                    {l.links.map(link => (
                                        <li key={link.url + link.title} className='my-1'>
                                            <Link
                                                className='hover:text-black text-gray-700'
                                                to={link.url}
                                            >
                                                {link.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )
                    })}
                </div>
                <div className='sm:flex justify-center items-center my-8'>
                    <div className='flex-1 text-center'>
                        <div className=''>
                            <Link to='/' className='flex items-center mr-1'>
                                <img
                                    alt='Mono FM'
                                    width='95px'
                                    height='100%'
                                    className='text-gray-300 group-hover:text-gray-500 transform duration-150 scale-95 group-hover:scale-100'
                                    src={monoLogo}
                                />
                            </Link>
                        </div>
                        <div className='text-left font-light my-2'>
                            <span>Copyright</span> © 2020 Mono FM. All rights reserved.
                        </div>
                    </div>
                    <div className='flex-1 text-center flex items-center sm:justify-end font-medium text-gray-600'>
                        <a
                            href={`https://twitter.com/${meta.twitterUsername}`}
                            className='text-gray-500 hover:text-gray-700 border-r px-3 py-1 mr-3'
                        >
                            <svg
                                height='16'
                                viewBox='0 0 18 15'
                                aria-label='twitter'
                                fill='currentColor'
                            >
                                <path
                                    d='M18 1.684l-1.687 1.684v.28c0 .307-.05.602-.123.886-.04 2.316-.777 5.387-3.816 7.81C6.404 17.115 0 12.907 0 12.907c5.063 0 5.063-1.684 5.063-1.684-1.126 0-3.376-2.243-3.376-2.243.563.56 1.689 0 1.689 0C.56 7.295.56 5.61.56 5.61c.563.561 1.689 0 1.689 0C-.563 3.368 1.124.561 1.124.561 1.687 3.368 9 4.49 9 4.49l.093-.046A6.637 6.637 0 0 1 9 3.368C9 1.353 10.636 0 12.656 0c1.112 0 2.094.506 2.765 1.286l.329-.163L17.437 0l-1.122 2.245L18 1.684z'
                                    fillRule='nonzero'
                                />
                            </svg>
                        </a>
                        <a
                            className='hover:text-black'
                            target='_blank'
                            rel='noopener noreferrer'
                            href='mailto:support@mono.fm'
                        >
                            support@mono.fm
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
