import React from 'react'
import Header from './header'
import Footer from './footer'

export default props => {
    return (
        <div className='flex flex-col min-h-screen w-full font-body relative'>
            <Header />
            <main className='flex-1 w-full'>{props.children}</main>
            <Footer />
        </div>
    )
}
