import React from 'react'
import './src/global.css'
import 'typeface-inter'
// import LogRocket from 'logrocket'
import Layout from './src/components/layout.js'

require('smooth-scroll')('a[href*="#"]')

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}

//Disable logrocket for now
// export const onClientEntry = () => {
//     if (process.env.NODE_ENV !== 'development') {
//         LogRocket.init('itpfkl/mono-site')
//         LogRocket.getSessionURL(sessionURL => {
//             window.Sentry.configureScope(scope => {
//                 scope.setExtra('sessionURL', sessionURL)
//             })
//         })
//     }
// }

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
        await import(`intersection-observer`)
    }
}
