import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import monoLogo from '../images/logo-dark.svg'
import { useLocation } from '@reach/router'

const left = [
    { title: 'Pricing', to: '/#pricing' },
    { title: 'Features', to: '/#features-podcast', hideOnMobile: true },
    { title: 'FAQs', to: '/faqs' },
    //@Todo blog and docs styling and content
    // { title: 'Blog', to: '/blog' },
    // { title: 'Docs', to: '/docs' },
]

export default props => {
    const [open, setOpen] = React.useState(false)
    const loc = useLocation()

    return (
        <header className='z-50 px-2 md:px-0 w-full bg-gray-50'>
            <nav className='px-2 w-full sm:max-w-5xl mx-auto flex items-center justify-between'>
                <div className='flex w-full sm:w-auto justify-between sm:justify-start items-center text-sm'>
                    <div>
                        <Link to='/' className='flex-shrink-0 flex items-center group mr-6'>
                            <img
                                alt='Mono FM'
                                width='95px'
                                height='100%'
                                className='text-gray-300 flex-shrink-0 group-hover:text-gray-500 transform duration-150 scale-95 group-hover:scale-100'
                                src={monoLogo}
                            />
                        </Link>
                    </div>
                    <div className='flex items-center space-x-2 ml-2'>
                        {left.map((r, i) => {
                            return (
                                <Link
                                    className={`${
                                        r.hideOnMobile ? 'hidden sm:inline-block' : ''
                                    } font-medium -my-px px-1 py-5 border-b-2 border-transparent text-gray-600 hover:text-gray-900`}
                                    activeClassName='border-gray-700'
                                    partiallyActive={r.to !== '/'}
                                    key={r.to + i}
                                    to={r.to}
                                >
                                    <span className='rounded px-2 py-1 hover:bg-gray-100'>
                                        {r.title}
                                    </span>
                                </Link>
                            )
                        })}
                    </div>
                </div>
                <div className='hidden sm:flex items-center text-sm space-x-4 text-gray-500 py-1'>
                    <a
                        className='px-3 py-1 rounded hover:bg-gray-50 hover:text-gray-900 transition duration-150'
                        rel='nofollow'
                        href={`https://app.mono.fm/login${loc.search}`}
                    >
                        Login
                    </a>
                    <OutboundLink
                        eventAction='signup_click'
                        className='border border-gray-300 hover:text-black hover:border-gray-800 rounded px-3 py-1 transition duration-150'
                        rel='nofollow'
                        href={`https://app.mono.fm/signup${loc.search}`}
                    >
                        Signup
                    </OutboundLink>
                </div>
                <div className='sm:hidden ml-4 text-sm mr-2'>
                    <button
                        className='rounded focus:outline-none focus:shadow-outline'
                        onClick={() => setOpen(!open)}
                        aria-label='Toggle Menu'
                    >
                        <svg
                            className='w-6 h-6 text-gray-600 '
                            fill='currentColor'
                            viewBox='0 0 20 20'
                        >
                            {open ? (
                                <path
                                    fillRule='evenodd'
                                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                                    clipRule='evenodd'
                                ></path>
                            ) : (
                                <path
                                    fillRule='evenodd'
                                    d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                                    clipRule='evenodd'
                                ></path>
                            )}
                        </svg>
                    </button>
                </div>
            </nav>
            {open && (
                <div className='sm:hidden flex flex-col mb-2 items-center'>
                    <a
                        className='block px-4 py-2'
                        rel='nofollow'
                        href={`https://app.mono.fm/login${loc.search}`}
                    >
                        Login
                    </a>

                    <OutboundLink
                        eventAction='signup_click'
                        className='block px-4 py-2'
                        rel='nofollow'
                        href={`https://app.mono.fm/signup${loc.search}`}
                    >
                        Sign Up
                    </OutboundLink>
                </div>
            )}
        </header>
    )
}
